import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { Title, Section, Box, Text } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/contact/Hero";

const Success = () => {
  return (
    <>
      <PageWrapper headerDark>
        <Helmet>
          <title>Contact Us</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Hero title="Get in touch">
          Please complete the form and are team will get back to you as soon as
          we can. Alternatively you can give us a call or use one of our direct
          to department email addresses.
        </Hero>
        <Section py={[20, 20, 50]}>
          <Container>
            <Title py={6} className="text-center text-success">
              We will reply you back as soon as possible.
            </Title>
            <Row className="d-flex align-items-start justify-content-center">
              <Col md="4" sm="10" className="mb-5 ">
                <Box className="mb-5">
                  <Title variant="card" fontSize="24px">
                    Call us
                  </Title>
                  <Text>0870 240 5538</Text>
                </Box>
              </Col>

              <Col md="4" sm="10" className="mb-5 ">
                <Box className="mb-5">
                  <Title variant="card" fontSize="24px">
                    Email us
                  </Title>
                  <Text>sales@gotalk.co.uk</Text>
                  <Text>support@gotalk.co.uk</Text>
                  <Text>accounts@gotalk.uk</Text>
                </Box>
              </Col>

              <Col md="4" sm="10" className="mb-5 ">
                <Box className="mb-5">
                  <Title variant="card" fontSize="24px">
                    Office Address
                  </Title>
                  <Text>Unit 2, Sandy Lane, The Broadway</Text>
                  <Text>Bury St Edmunds, Suffolk, IP31 3FA</Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Success;
